@import '../../../global.scss';

.schedule-header-row{
    width: auto;
    min-width: 30%;
    display: flex;
    flex-direction: column; 
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 27px;

    @media screen and (max-width: $mobile-nav-bar) {
        margin-top: 25px;
    }
    // Media query for laptops
    @media (max-height: 600px) and (min-width: 769px){ 
        margin-top: 25px;
    }

    .header-first-row{
        display: flex;
        flex-direction: row; 
        align-items: center;
        justify-content: space-between;
        gap: 20px; 
    }
    h1{
        font-weight: normal;
        color:$theme-white-color; 

        @media screen and (max-width: $mobile-nav-bar) {
            margin-left: 20px;
        }
        // Media query for laptops
        @media (max-height: 600px) and (min-width: 769px){ 
            margin-left: 20px;
        }
    }
    
}
