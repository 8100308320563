@import url('https://fonts.googleapis.com/css2?family=Bona+Nova:ital,wght@0,400;0,700;1,400&display=swap');

// Fonts
$main-font:  "Bona Nova", serif;

// Color Themes
$theme-white-color: white;
// $theme-black-color:rgba(0, 0, 0, 0.9);
$theme-black-color:black;
$theme-pink-color:#FAC5EC;

// Media
$mobile-nav-bar:770px;
$mobile: 600px;