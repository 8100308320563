@import '../../../global.scss';

.contact-container{
    height: 100%;
    width: 40%;
    /* Ensure the component is above the dots */
    position: relative; 
    z-index: 2; 

    @media screen and (max-width: 1200px) {
       height: 40%; 
       width: 100%;
    }
   
}
.contact-content{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    h1{
        margin:  0 0 10px 0;
    }
    h2{
        font-weight: normal;
    }

    @media screen and (max-width: 1200px) {
        height: 50%;
    }
}
.left-dot-container{
    margin-left: 10px;
    direction: ltr;
}
.contact-icons{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.contact-icons img {
    cursor: pointer; 
}
.contact-icons a {
    z-index: 10; 
}

@keyframes rotate {
    0% {
        transform: rotate(-10deg);
      }
      50% {
        transform: rotate(10deg);
      }
      100% {
        transform: rotate(-10deg);
      }
}
.moving-icon {
    animation: rotate 2s infinite;
}