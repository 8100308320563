.calender-header{
    background-color: rgba(255, 255, 255, 0.25); 
    box-shadow: 0 0 40px rgba(134, 111, 111, 0.8); 

    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    height: 10%;
    width: 100%;
}
.calendar-header-day {
    width: 14.285%;
  }