.rows-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0; /* Ensure it's above other content */

  @media screen and (max-width: 1200px) {
        display: none;
    }
}
.row{
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
}
