@import '../../global.scss';

.schedule-page {
    background-color: $theme-black-color; 
    background-size: cover;
    background-position: center;
    height: 100vh;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;

    .overlay {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-around;
    }  
    .video-container {
      position: relative;
      width: 100%;
      height: 100vh; 
      overflow: hidden;
    }
    .video-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7); 
    }
}
  