@import '../../global.scss';

.main-page{
  overflow-y: auto; // Allow vertical scrolling
  scrollbar-width: none; // Hide scrollbar for Firefox
  -ms-overflow-style: none; // Hide scrollbar for IE and Edge

  // For WebKit browsers
  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari, and Opera
  }


  .main-page-content {
    font-weight: bold;
    min-height: 100vh; 
    height: 100vh;
    color:$theme-white-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin:10px;
  }
  .video-container {
    position: relative;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
  }
  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); 
  }
  .header-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      white-space: nowrap; // Prevent wrapping by default
    }
    @media screen and (max-width: $mobile-nav-bar) {
      margin-top: 50px;
      h1 {
        white-space: normal; // Allow wrapping on mobile
      }
    }
   
     // Media query for laptops
     @media (max-height: 600px) and (min-width: 769px){ 
      flex-direction: row;
    }
  }
  .header-container img {
    min-height: 120px;
    min-width: 120px;

    max-height: 150px;
    max-width: 200px;

    margin-left: 10px; 
  
    border: 3px solid #a9a39c;
    border-radius: 10%;

    box-shadow: rgba(150, 158, 112, 0.45) 0px 50px 100px -20px, rgba(149, 158, 100, 0.25) 0px 30px 60px -30px, rgba(29, 30, 23, 0.60) 0px -2px 6px 0px inset;
  }
  .about-me-text {
    margin-top: 10px;

    div{
      margin-bottom: 20px; 
    }

  }
}
