.inactive-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
}

.inactive-overlay .line {
    background-color:gray;
    height: 0.1px;
    width: 100%;
    min-width: 300px;
    transform: rotate(25deg);
}

  
