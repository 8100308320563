@import '../../../../global.scss';

.custom-card{
    background-color: $theme-black-color;
    box-shadow: 0 0 50px rgba(17, 15, 15, 0.8); 
    color:  $theme-white-color;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center; 

    border-radius: 5px;
}
.glass-container{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.glass {
    position: absolute;
    background: rgba(183, 160, 160, 0.17);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.9px);
    -webkit-backdrop-filter: blur(1.9px);
    border: 1px solid rgba(183, 160, 160, 0.35);
}
.glass-1 {
    top: 20px;
    right: 30px;
    width: 141px; 
    height: 67px; 
    transform: rotate(0.354215turn);
    opacity: 0.04;
}
.glass-2 {
    top: 175px;
    left: 114px;
    width: 140px; 
    height: 148px; 
    transform: rotate(0.201841turn);
    opacity: 0.06;
}
.glass-3 {
    top: 175px;
    right: 10px;
    width: 100px; 
    height: 60px; 
    transform: rotate(0.1841turn);
    opacity: 0.18;
}
.glass-4 {
    top: 270px;
    right: 150px;
    width: 100px; 
    height: 90px; 
    transform: rotate(0.2turn);
    opacity: 0.08;
}
.glass-5 {
    top: 380px;
    right: 200px;
    width: 100px; 
    height: 150px; 
    transform: rotate(0.1841turn);
    opacity: 0.04;
}
.glass-6 {
    bottom: 0px;
    left: 10px;
    width: 80px; 
    height: 150px; 
    transform: rotate(0.1841turn);
    opacity: 0.14;
}
.custom-card-dot-container{
    margin-top: 10px;
    width:100%;
}
.custom-card-left-dot-container{
    direction: ltr;
    margin-left: 10px;
}
.custom-card-right-dot-container{
    direction: rtl;
    margin-left: -10px;
}



