.costs-container{
    height: 100%;
    width: 40%;
   /* Ensure the component is above the dots */
   position: relative; 
   z-index: 2; 

    @media screen and (max-width: 1200px) {
       height: 40%; 
       width: 100%;
    }
}
.cost-content{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 60%;
    h1{
        margin:  0 0 10px 0;
    }
}
.right-dot-container{
    margin-right: 10px;
    direction: rtl;
}
