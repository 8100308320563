@import url('https://fonts.googleapis.com/css?family=Concert+One&display=swap');

.switch-container {
  display: flex;
  align-items: center; 
  justify-content: center; 

}
.tabber {
  height: 100%;
  margin: 0;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.tabber label {
  color: white;
  user-select: none;
  padding: 3rem;
  cursor: pointer;
  will-change: transform;
  transform: translateZ(0px);
  transition: transform 125ms ease-in-out, filter 125ms ease-in-out;
  z-index: 2; // Ensure the labels are on top
}

.tabber label:hover {
  transform: scale(1.15);
}

.tabber input[type="radio"] {
  display: none;
}

.tabber .blob {
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 1; // Ensure the blob is below the labels
  border-radius: 4rem;
  transition: transform 150ms ease, background-color 150ms ease;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\"><defs><filter id=\"goo\"><feGaussianBlur in=\"SourceGraphic\" stdDeviation=\"10\" result=\"blur\" /><feColorMatrix in=\"blur\" mode=\"matrix\" values=\"1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9\" result=\"goo\" /><feComposite in=\"SourceGraphic\" in2=\"goo\" operator=\"atop\"/></filter></defs></svg>#goo");
}

.tabber .blob.left {
  transform-origin: right center;
  //background-color: rgba(45, 167, 189, 1); 
  animation: stretchyRev 0.5s ease-in-out forwards;
}

.tabber .blob.right {
  transform-origin: left center;
  //background-color: rgba(45, 167, 189, 1); 
  animation: stretchy 0.5s ease-in-out forwards;
}

.tabber .blob:before, .tabber .blob:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  border-radius: 100%;
  background-color: inherit;
  transform: scale(1.15);
  transition: transform 150ms ease;
  animation: pulse 1.5s infinite alternate; 
  
  @media (max-width: 1200px) { 
    height: 80%;
  }
}

.tabber .blob:before {
  background-color: rgba(45, 167, 189, 1); 
  left: 0;
  animation-delay: 0.15s; 
}

.tabber .blob:after {
  background-color: rgba(45, 167, 189, 1); 
  right: 0;
}

@keyframes stretchy {
  0% {
    transform: translateX(0) scaleX(1);
  }
  50% {
    transform: translateX(0) scaleX(2);
  }
  100% {
    transform: translateX(100%) scaleX(1);
  }
}

@keyframes stretchyRev {
  0% {
    transform: translateX(100%) scaleX(1);
  }
  50% {
    transform: translateX(0) scaleX(2);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}

@keyframes pulse {
  0%, 50% {
    transform: scaleX(1);
  }
  25%, 75% {
    transform: scaleX(1.5);
  }
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  @keyframes pulse {
    0%, 50% {
      transform: scaleX(1.3);
    }
    25%, 75% {
      transform: scaleX(1.5);
    }
  }
}