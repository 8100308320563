.calender-day{
    position: relative; /* Set position to relative */
    background-color: rgba(255, 255, 255, 0.25); /*  25% opacity */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Horizontal offset, vertical offset, blur radius, and color */
    outline: 2px solid rgba(0, 0, 0, 0.1); /* Border */
    display: flex; 
    align-items: center; /* Align items vertically */
    justify-content: center; /* Align items horizontally */    
    transition: 0.5s; 
}
.active{
    cursor: pointer; 
}
.active:hover {
    font-weight: bold;
    transform: scale(0.9); 
    transition: 0.5s; 
}
.inactive{
    background-color: rgba(185, 178, 178, 0.562); /* White color with 25% opacity */
}