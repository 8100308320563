@import '../../global.scss';

.contact-and-coasts-page {
  background-image: url("../../assets/images/secondPageBackground.svg");
  background-color:$theme-white-color; 
  background-size: cover;
  background-position: center;
  height: 100vh;

  display: flex;
  align-items: center; /* Center children vertically */
  justify-content: space-around;

  @media screen and (max-width: 1200px) {
    height: 200vh; 
  }
}
.cards-container{
  width: 85%;
  height: 80%;
  max-height: 600px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    margin: 30px;
    width: 60%;
    max-height: initial;
    flex-direction: column;
    justify-content: space-between;
  }
  // Media query for laptops
  @media (max-height: 600px) and (min-width: 769px){ 
    margin-top: 60px;
  }
  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}
