@import '../../global.scss';

.custom-form{ 
    // height: 50%;
    width: 100%;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 20%;
    color:#000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1; /* Ensure the form appear above the glass container */
    input{
        height: 12%;
        min-height: 30px;
        margin-top: 15px;
        text-align: center;
        box-shadow: 5px 2px 14px $theme-black-color;
        border-radius: 15px;
        width: 80%;

        // Media query for laptops
        @media (max-height: 600px) and (min-width: 769px){ 
            min-height: initial;
            height: 20%;
        }
    }
    select {
        height: 12%;
        min-height: 30px;
        margin-top: 15px;
        text-align: center;
        box-shadow: 5px 2px 14px $theme-black-color;
        border-radius: 15px;
        width: 80%;
        appearance: none; // Remove default arrow in some browsers
        color:#000000;
        text-align: center; // Center the text in each option
        text-align-last: center; // For some browsers to center the selected text
        
        option{
            direction: rtl; // This will help with Hebrew text alignment
            text-align: center; // Center the text in each option
            background-color: white; // Ensure background color for options
        }
        // Media query for laptops
        @media (max-height: 600px) and (min-width: 769px){ 
            min-height: initial;
            height: 20%;
        }
    }
    button{
        width: 40%;
        min-height: 30px;
        background-color: #333;
        color: white;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    button:hover {
        font-weight: bold;
        transform: scale(1.1); /* Scale up the text by 10% */
        transition: transform 0.3s; 
    }
}