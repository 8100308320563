@import '../../../../global.scss';

.line{
    width: 100px;
    height: 1px;
    background-color: #1c1c1c;
    
  @media screen and (max-width: 768px) {
        width: 5px;
        height: 70px;
    }
}