@import './global.scss';

body,html{
  margin:0;
  padding: 0;
  overflow-x: hidden;

  font-family: $main-font;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  font-size:100%;

  font-size: min(110%, 20px); /* Set a minimum font size of 12px */

}
.app {
  overflow-x: hidden;
  width: 100vw;
}
