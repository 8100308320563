@import '../../global.scss';

.desktop-menu{
    display: flex;
    @media screen and (max-width: $mobile-nav-bar) {
        display: none;
    }
}
.nav-icon:hover {
    transform: scale(1.1); /* Scale up the text by 10% */
    transition: transform 0.3s; /* Add transition effect */
}
.nav-icon-text{
    font-weight: bold;
}
.main-page-styling{
    color:$theme-white-color !important;
}
.costs-page-styling,.contact-page-styling{
    color:$theme-black-color !important; 
}
.schedule-page-styling{
    color:$theme-white-color !important;
}
.nav-icon-text{
    font-family: $main-font;
    letter-spacing: 1px;
}
.burger-menu-icon{
    display: none !important; 
    svg{
        font-size: 2rem ;
    }
    @media screen and (max-width: $mobile-nav-bar) {
        display: flex !important;
     }
}
.burger-menu-drawer{ 
    height: 100%;
    span{
        text-align: center;
    }
    background-color: transparent !important;
}
.burger-menu-drawer-list{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    li{
        cursor: pointer; 
    }
}