@import '../../global.scss';

.custom-modal button{
    padding: 0 ;
    margin: 0 ;
}
.custom-modal-info-icon-wrapper {
    cursor: pointer;
}

.modal-header {
    text-align: center; // Center the text
    width: 100%; // Ensure it takes the full width
}

.custom-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; 
    max-width: 400px; 
    background-color: $theme-black-color;
    color: $theme-white-color;
    border: 2px solid rgba(255, 255, 255, 1.3);
    padding: 20px;
    box-sizing: border-box;
    @media screen and (max-width: 600px) {
        width: 90%; 
    }
}
.custom-modal-close-button{
    color: $theme-white-color !important;
}

