
.topic {
  display:flex;
  column-gap: 5px;
}
.topic-text{
  margin-left: 7px;
}
.topic .info-icon {
  cursor: pointer; 
}