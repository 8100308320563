@import '../../../global.scss';

.calender{
    height: 70%;
    color:$theme-white-color; 
    width: 90%;
    // Media query for laptops
    @media (max-height: 600px) and (min-width: 769px){ 
        margin-bottom: 20px;
    }
}
.days-container {
    height: 90%; //10% goes to the calender header
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 columns for 7 days, each day takes 1 fraction unit */
}